import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductReviewForm from "../../components/ProductReviewForm";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ProductReviewFormPage() {
  const [productId, setProductId] = useState([]);
  const [productNumber, setProductNumber] = useState();
  const endpoint = window.location.href.split("/")[4];
  const navigate = useNavigate();
  const axios = useAxios();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const invitationUrl = window.location.href
      .replace("https://", "")
      .replace("http://", "");
    axios
      .get("/data/invitationdata/?invitationUrl=" + invitationUrl)
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        axios
          .get(
            "/data/transaction-get/" +
              res.data.invitation_url_complete
                .split("?")[1]
                .split("&")[0]
                .split("=")[1]
          )
          .then((response) => {
            if (response.data.transaction_state_2 == "completed")
              navigate("/transaction_completed/" + endpoint, {
                replace: true,
              });
          })
          .catch((err) => {
            //.log(err);
          });
        setProductId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[4]
            .split("=")[1]
            .split(",")
        );

        setProductNumber(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[4]
            .split("=")[1]
            .split(",").length
        );
      })
      .catch((err) => {
        //.log(err);
      });
  }, [productNumber]);

  return (
    <>
      {productNumber >= 2 ? (
        <>
          <ProductReviewForm
            dataindex={0}
            textAreaId="content"
          ></ProductReviewForm>
          <ProductReviewForm
            dataindex={1}
            textAreaId="content"
          ></ProductReviewForm>
        </>
      ) : (
        <>
          <ProductReviewForm
            dataindex={0}
            textAreaId="content"
          ></ProductReviewForm>
        </>
      )}
    </>
  );
}

export default ProductReviewFormPage;
