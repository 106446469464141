import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect, useRef } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import togglePassword from "../../utils/tools/togglePassword";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`; /* Clé publique récupérée du serveur */

function LoginAdminPage() {
  const axios = useAxios();
  const captchaRef = useRef(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem("langPage", lang);

    togglePassword();
  }, []);

  //comportement
  function handleChange(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = JSON.stringify(user);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    axios
      .post("/support-login", user)
      .then((res) => {
        if (res.data["success"] == true) {
          //.log(res.data);
          window.location.href = "/admin-dashboard";
        } else {
          //.log("Error to connect");
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Invalid password or username", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const onLoad = () => {
    captchaRef.current.execute();
  };

  // useEffect(() => {
  //   if (token) //.log(`hCaptcha Token: ${token}`);
  // }, [token]);

  return (
    <>
      <div className="row d-flex align-items-center my-2 my-md-5">
        <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
          <h3>{t("SigninConnectVeritrust")}</h3>
        </div>
        <div className="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
          <div className="form__account">
            <div className="row d-flex flex-column align-items-center">
              {/* <div className="col-12 col-md-6 g-0 mx-auto">
                <div className="orbymail text-center">
                  <div className="separator">
                    {" "}
                    {t("SignEnterLoginPassword")}
                  </div>
                </div>
              </div> */}

              <form id="login" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6 mx-auto">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      value={user.email}
                      onChange={handleChange}
                      name="email"
                      id="usermail"
                      aria-describedby="emailHelp"
                      title="User Email"
                      placeholder="User Email"
                    />
                    <span className="input-group-text bg-success text-white border-success">
                      <i className="flaticon-user"></i>
                    </span>
                  </div>
                  <div id="emailHelp" className="form-text mb-3"></div>
                  <div className="input-group mb-3 ">
                    <input
                      type="password"
                      className="form-control"
                      value={user.password}
                      onChange={handleChange}
                      id="userpassword"
                      name="password"
                      title="Password"
                      placeholder="Password"
                    />
                    <span className="input-group-text bg-success toggle-password text-white border-success">
                      <i className="flaticon-hide"></i>
                    </span>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberme"
                      checked={user.stayConnected && true}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="rememberme">
                      {t("HeaderNavbarConnected")}
                    </label>
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn-block btn btn-success text-white"
                      id="userbutton"
                    >
                      {t("HeaderSignin")}
                    </button>
                  </div>
                  <Link
                    className="mt-3 primary-text-center"
                    to="/resetpassword"
                  >
                    {t("HeaderNavbarPass")}
                  </Link>
                </div>
              </form>
            </div>

            {/* <div className="col-12 col-md-6 mx-auto">
              <div className="form__footer">
                <HCaptcha
                  sitekey="1be3a25e-95cb-441d-a951-f140b9e09428"
                  explicit={true}
                  onLoad={onLoad}
                  onVerify={setToken}
                  ref={captchaRef}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default LoginAdminPage;
