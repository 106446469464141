import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ProductReview from "../../components/ProductReview";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ProductReviewAlone() {
  const axios = useAxios();
  // const BaseUrl = "https://api.veritatrust.com/review-product/";

  let { id } = useParams();

  const [reviews, setReviews] = useState(null);

  const [product, setProduct] = useState(null);
  const [displayPendingMessage, setDisplayPendingMessage] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      try {
        const response = await axios.get(
          `/data/productreview/productreview/${id}`
        );
        const reviewsData = response.data;
        setReviews(reviewsData);

        if (reviewsData.length > 0) {
          const productId = reviewsData[0].product_id;
          const productResponse = await axios.get(
            `/productsearch?q=${productId}`
          );
          const productData = productResponse.data;
          setProduct(productData);
          //.log(productResponse.data);
          setDisplayPendingMessage(false);
        }
      } catch (error) {
        //.error(error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <>
      <section className="bg-success profil-main">
        <div className="container py-2 py-lg-5">
          <div className="row">
            <div className="col-lg-8">
              {product && (
                <Link
                  to={
                    "/" +
                    t("lang") +
                    "/review-product/" +
                    product[0].id +
                    "-" +
                    product[0].product_name
                      .replace(/[^a-zA-Z0-9]/g, "-")
                      .toLowerCase()
                  }
                  className="link-light"
                >
                  Review in {product[0].product_name}{" "}
                </Link>
              )}
              <div className="reviews-list">
                {reviews &&
                  reviews.map((review, index) => (
                    <div>
                      <ProductReview key={index} data={review} />
                    </div>
                  ))}

                {displayPendingMessage === true && (
                  <>
                    <h3 className="text-muted">review in pending status</h3>
                    <Link
                      className="btn btn-secondary btn-sm mt-2"
                      to="/account"
                    >
                      Views details
                    </Link>
                  </>
                )}
              </div>
              {product && (
                <a
                  className="btn btn-secondary btn-sm mt-2"
                  href={
                    "/" +
                    t("lang") +
                    "/review-product/" +
                    product[0].id +
                    "-" +
                    product[0].product_name
                      .replace(/[^a-zA-Z0-9]/g, "-")
                      .toLowerCase()
                  }
                >
                  Views details
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductReviewAlone;
