import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UserReward({ data, dataLenght }) {
  const NEWBIE = 5;
  const BRONZE = 20;
  const SILVER = 50;
  const GOLD = 99;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  //const PLATINUM = 150;
  const [reviewNumberNextLevel, setReviewNumberNextLevel] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);

    switch (data.levelAccount) {
      case 0:
        setReviewNumberNextLevel(NEWBIE - dataLenght);
        break;
      case 1:
        setReviewNumberNextLevel(BRONZE - dataLenght);
        break;
      case 2:
        setReviewNumberNextLevel(SILVER - dataLenght);
        break;
      case 3:
        setReviewNumberNextLevel(GOLD - dataLenght);
        break; // il te reste reviewNumberNexLevel pour atteindre le niveau supérieur
    }
  });

  let levelname = "Newbie";
  let levelValueClass = "value level level" + data.levelAccount.toString();
  let levelClass = "";

  switch (data.levelAccount) {
    case 0:
      levelname = "Newbie";
      break;
    case 1:
      levelname = "Bronze";
      break;
    case 2:
      levelname = "Silver";
      break;
    case 3:
      levelname = "Gold";
      break;
    case 4:
      levelname = "Platinum";
      break;
  }

  switch (data.levelAccount) {
    case 0:
      levelClass = "level0";
      break;
    case 1:
      levelClass = "level1";
      break;
    case 2:
      levelClass = "level2";
      break;
    case 3:
      levelClass = "level3";
      break;
    case 4:
      levelClass = "level4";
      break;
  }

  let levelnames = ["Newbie", "Bronze", "Silver", "Gold", "Platinum"];
  let levelClasses = ["level0", "level1", "level2", "level3", "level4"];

  const axios = useAxios();
  const [userData, setUserData] = useState({}); //useSelector((state) => state.auth.user);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      setUserData(response.data);
    };
    fetchReviews();
  }, []);

  return (
    <>
      <div
        className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <h2>
          {t("AccountH1")} {userData.first_name} {userData.last_name}
        </h2>

        <p className="lead">
          {/* You have {data.totalReward} VeritaCoins (VT) in your wallet! */}
          {t("AccountYouHave", { amount: data.approuvedRaward })}
        </p>
        <div className="row mb-lg-4">
          <div className="state col-md-6 d-flex justify-content-around mb-3">
            <div className="value approuved">
              <div className="icone">
                <i className="flaticon-cube-3d"></i>
              </div>
              <div>
                <div className="number">
                  <span>{data.approuvedRaward}</span> VT
                </div>
                <div className="state">{t("AccountVTApproved")}</div>
              </div>
            </div>
            <div className="value pending">
              <div className="icone">
                <i className="flaticon-time-left"></i>
              </div>
              <div>
                <div className="number">
                  <span>{data.pendingReward}</span> VT
                </div>
                <div className="state">{t("AccountVTPending")}</div>
              </div>
            </div>

            <div className={data.totalReward == 0 ? "level0" : levelValueClass}>
              <div className="icone">
                <i className="flaticon-logo-level"></i>
              </div>
              <div>
                <div className="state">
                  {data.totalReward == 0 ? "Newbie" : levelname}
                </div>
              </div>
            </div>
          </div>

          <div className="level-upgrade col-md-6">
            <p>
              <Trans
                i18nKey="accountCurrentLevelWithHTML"
                values={{
                  reviewsNb: reviewNumberNextLevel,
                  levelBadge: data.totalReward === 0 ? "Newbie" : levelname,
                  ReviewsNumber: data.totalReward,
                  nextLevelBadge:
                    data.totalReward === 0
                      ? "Bronze"
                      : levelnames[data.levelAccount + 1],
                }}
                components={{
                  1: (
                    <strong>
                      <span
                        className={
                          data.totalReward === 0 ? "level0" : levelClass
                        }
                        id="level-name"
                      >
                        {data.totalReward === 0 ? "Newbie" : levelname}{" "}
                        Influencer
                      </span>
                    </strong>
                  ),
                  2: (
                    <span
                      className={
                        data.totalReward === 0
                          ? "level1"
                          : levelClasses[data.levelAccount + 1]
                      }
                      id="level-next"
                    >
                      {data.totalReward === 0
                        ? "Bronze"
                        : levelnames[data.levelAccount + 1]}{" "}
                      Influencer
                    </span>
                  ),
                  3: (
                    <strong className="text-success" id="review-privilege">
                      78
                    </strong>
                  ),
                }}
              />
            </p>
          </div>
        </div>

        <div className="row">
          <div className="total col-12">
            <p>
              <Trans
                i18nKey="AccountVTSinceRegistration"
                values={{
                  amount: data.approuvedRaward,
                }}
                components={{
                  1: <span className="text-success"></span>,
                }}
              />
            </p>
          </div>
        </div>

        <div className="d-grid gap-2 col-lg-6 mx-auto">
          <Link
            className="btn btn-success d-block mt-2 text-white"
            to={`/${window.localStorage.getItem(
              "langPage"
            )}/add-review-create-for-beta`}
          >
            {t("AccountAddReview")}
          </Link>
        </div>
      </div>
    </>
  );
}

export default UserReward;
