import React from "react";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";

import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
// import star0 from "../../assets/star-1.png";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useAxios } from "../../utils/hook/useAxios";

import level0 from "../../assets/level0.jpg";
import level1 from "../../assets/level1.jpg";
import level2 from "../../assets/level2.jpg";
import level3 from "../../assets/level3.jpg";
import level4 from "../../assets/level4.jpg";
import LikeComponent from "../likeComponent";
import ShareComponent from "../shareComponnet";
import ReportComponent from "../ReportComponent";

import moment from "moment";
import PopoverVerified from "../PopoverVerified";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function ProductReview({ data }) {
  let srcvalue = "star" + data.rating.toString();
  let srcimg = star1;

  let imgUser = level0;

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
  }

  switch (data.level_account) {
    case 0:
      imgUser = level0;
      break;
    case 1:
      imgUser = level1;

      break;
    case 2:
      imgUser = level2;
      break;

    case 3:
      imgUser = level3;
      break;

    case 4:
      imgUser = level4;
      break;

    default:
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hashtransaction, setHashtransaction] = useState("");
  const axios = useAxios();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      axios
        .get(`/data/transaction-get/${data.job_id}`)
        .then((response) => {
          setHashtransaction(response.data.hash_transaction);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchReviews();
  }, []);

  return (
    <>
      <Helmet>
        {/* Common meta tags */}
        <meta charSet="utf-8" />
        <title>{data.title}</title>
        <meta name="description" content={data.content} />

        {/* Open Graph meta tags for social media */}
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.content} />
        <meta
          property="og:url"
          content={"https://api.veritatrust.com/productreview/" + data.id}
        />
        <meta property="og:image" content={star5} />
        <meta property="og:type" content="website" />
        {/* Add more OG tags as needed */}
      </Helmet>
      <div
        className="bg-white p-3 mb-2 rounded-5 shadow aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="profil d-flex pb-2">
          <img
            alt="product review"
            src={data.profile_url ? data.profile_url : imgUser}
            width="50"
            height="50"
          />
          <div className="info ms-3">
            <Link to={"/" + t("lang") + "/users/" + data.userid}>
              <p className="name lead mb-0">
                {data.nickName
                  ? data.nickName
                  : data.last_name
                  ? data.first_name + " " + data.last_name[0] + "."
                  : "Anonymous"}
              </p>
            </Link>
            <p className="analyse text-muted mb-0">
              {data.Nbre} {t("ReviewMerchantPageReviews")} •{" "}
              <i className="flaticon-location"></i> FR
            </p>
          </div>

          <div className="date text-primary ms-auto text-end small">
            {moment(data.createdAt).format("MMM DD, YYYY")}
          </div>
        </div>
        <div className="notation d-flex justify-content-between align-items-center py-2 mb-2">
          <div className="note small me-auto">
            <div className="form__star d-flex justify-content-start">
              <div>
                <img
                  alt="Noté 5 sur 5 étoiles"
                  src={srcimg}
                  width="120"
                  height="70"
                  onClick={openModal}
                />
                <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal}>
                  <img src={srcimg} alt="product review" />
                </ReactModal>
              </div>
            </div>
          </div>
          <PopoverVerified hash_transaction={hashtransaction} />
        </div>
        <div className="content mb-2">
          {/* <p>
            <span className="badge bg-primary">{data.product_name}</span>
          </p> */}
          <h3 className="text-primary">
            {" "}
            <Link to={"/" + t("lang") + "/productreview/" + data.id}>
              {data.title}{" "}
            </Link>{" "}
          </h3>
          <p className="text-break">{data.content}</p>
          {/* <p className="text-muted mb-1">
            {t("ReviewMerchantPageExperienceDate")}
            {": "}
            {moment(data.experienceDate).format("MMM DD, YYYY")}
          </p> */}
          <ul className="d-flex">
            {data.image_video &&
              data.image_video.split(",").map((image, index) => (
                <li key={index}>
                  <a href="#">
                    <img src={image} alt="Reviews" height="100" width="100" />
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="share d-flex justify-content-around">
          <LikeComponent
            reviewdata={data}
            reviewtype={"product_review"}
          ></LikeComponent>
          <ShareComponent
            Url={"https://api.veritatrust.com/productreview/" + data.id}
          ></ShareComponent>
          <ReportComponent
            reviewdata={data}
            reviewtype="product_review"
          ></ReportComponent>
        </div>
      </div>
    </>
  );
}

export default ProductReview;
