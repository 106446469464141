import React, { useState, useEffect, useRef } from "react";
import { createPopper } from "@popperjs/core";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/flaticon_veritatrust.css";
import { Trans, useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

function PopoverVerified({ hash_transaction }) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [popover, setPopover] = useState(null);
  const popoverRef = useRef(null);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const showPopover = () => {
    setPopoverVisible(true);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const updatePopover = () => {
    if (target && popover) {
      createPopper(target, popover, {
        placement: "top",
      });
    }
  };

  const handleTargetRef = (element) => {
    setTarget(element);
    updatePopover();
  };

  const handlePopoverRef = (element) => {
    setPopover(element);
    updatePopover();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        hidePopover();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  const popoverTitle =
    '<i class="flaticon-cube-3d me-1"></i> Verified via Blockchain';

  return (
    <div className="verified" ref={popoverRef}>
      <button
        type="button"
        className="certified small bg-success text-white d-inline rounded-3"
        data-bs-html="true"
        data-bs-trigger="focus"
        data-bs-toggle="popover"
        data-bs-placement="top"
        data-bs-custom-class="veritatrust-popover"
        data-bs-content={`We use the blockchain to track and verify the transaction. <br><a href='https://testnet.ccdscan.io/transactions?dcount=1&dentity=transaction&dhash=${hash_transaction}' target='_blank'>View the onchain transaction</a> | <a href='#' target='_blank'>What is Blockchain?</a>`}
        ref={handleTargetRef}
        onClick={showPopover}
      >
        <span className="text-white">
          <i className="flaticon-cube-3d me-1"></i>{" "}
          {t("ReviewMerchantPageVerified")}
        </span>
      </button>
      {popoverVisible && (
        <div
          ref={handlePopoverRef}
          className="popover veritatrust-popover"
          data-bs-placement="top"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="popover-header"
            dangerouslySetInnerHTML={{ __html: popoverTitle }}
          />
          <div className="popover-body">
            {t("PopoverVerifedBlockchainMsg1")}. <br />
            <a
              href={`https://testnet.ccdscan.io/transactions?dcount=1&dentity=transaction&dhash=${hash_transaction}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("PopoverVerifedBlockchainMsg2")}
            </a>{" "}
            |{" "}
            <a href="#" target="_blank" rel="noopener noreferrer">
              {t("PopoverVerifedBlockchainMsg3")}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default PopoverVerified;
